import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSoundcloud, faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className="Footer">
      <div className="SocialLinkBox">
        <a href="https://www.github.com/randose" target="_blank" rel="noreferrer">
          <FontAwesomeIcon className="SocialLink" icon={faGithub} />
        </a>
        <a href="https://www.twitter.com/DanielRosenwald" target="_blank" rel="noreferrer">
          <FontAwesomeIcon className="SocialLink" icon={faTwitter} />
        </a>
        <a href="https://soundcloud.com/cherry-beach" target="_blank" rel="noreferrer">
          <FontAwesomeIcon className="SocialLink" icon={faSoundcloud} />
        </a>
        <a href="https://www.instagram.com/danielxrose" target="_blank" rel="noreferrer">
          <FontAwesomeIcon className="SocialLink" icon={faInstagram} />
        </a>
      </div>
    </div>
  );
}

export default Footer;