import './Main.css';
import BCard from './BCard';

function Main() {
  return (
    <div className="main">
      <BCard />
    </div>
  );
}

export default Main;