import './Section.css';

function Section(props) {
  const item = props.item
  return (
    <div className="Section">
      <h3 className="SectionHeader">{item.header}</h3>
      <p className="SectionText">{item.text}</p>
    </div>
  );
}

export default Section;