import './Info.css';
import emailicon from './media/emailicon.png';
import linkedinlogo from './media/linkedinlogo.png';

function Info() {
  return (
    <div className="InfoSection">
      <h1 className="Title">Daniel Rosenwald</h1>
      <h4 className="SubTitle">Tech | Real Estate | Music</h4>
      <div className="LinkBox">
        <a href="https://www.rosenwaldequities.com" target="_blank" rel="noreferrer"
          className="Link">rosenwaldequities.com</a>
      </div>
      <div className="ButtonBar">
        <a href="mailto:daniel@rosenwaldequities.com" target="_blank" rel="noreferrer" className="LinkButton">
          <button className="Button Button-White">
            <img src={emailicon} width="16px" alt="Email icon" />
            <span className="ButtonText ButtonText-White">Email</span>
          </button>
        </a>
        <a href="https://www.linkedin.com/in/rosenwald/" target="_blank" rel="noreferrer">
          <button className="Button Button-Blue">
            <img src={linkedinlogo} width="16px" alt="LinkedIn logo" />
            <span className="ButtonText ButtonText-Blue">LinkedIn</span>
          </button>
        </a>
      </div>
    </div>
  );
}

export default Info;