import './BCard.css';
import Info from './Info';
import Section from './Section';
import Footer from './Footer';
import headshot from './media/DanielRosenwaldHeadshot.jpg';
import sectiondata from './sectiondata.js';

function BCard() {
  const sections = sectiondata.map(sec => { return <Section key={sec.header} item={sec} /> })
  /* can also use object spreading syntax to pass all the args in directly. Syntax: <Component key={item.key} {...item} /> */
  return (
    <div className="BCard">
      <img src={headshot} className="BCardPic" alt="headshot" />
      <div className="BCardInfoBox">
        <Info />
        {sections}
      </div>
      <Footer />
    </div>
  );
}

export default BCard;