const sectiondata = [
  {
    header: 'About',
    text: "I'm passionate about building software that creates efficiency and value for users. I'm also passionate about executing real estate deals that create value for investors. Currently, I'm working on a project at the cross-section of these two passions. Stay tuned..."
  },
  {
    header: 'Interests',
    text: "I worked as a touring music producer and DJ a few years back and still love to produce in my free time. Check out my SoundCloud link below. I'm also a golfer and tennis player, and love traveling to new places. "
  }
]

export default sectiondata;